import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { useUserAgent } from 'hooks/user-agent';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import numberToWords from 'number-to-words';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { shouldSubmitCandidate } from 'utils/checks-utils';

import { Form } from 'components/Form/Form/Form';

import {
  getFormByRefereeId,
  saveCustomReference,
  selectCandidateApplication,
  submitCandidateApplication,
} from 'api/candidate-application-slice';

import styles from './CandidateCustomReference.module.scss';

const CandidateCustomReference = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user_agent } = useUserAgent();
  const { token, replacement } = useParams();

  const {
    application: { candidate, brand, candidate_checks, check_types, forms, country },
    fetched,
  } = useSelector(selectCandidateApplication);

  const { getUpload, signUpload, createUpload, deleteUpload } =
    useCandidateApplicationUploadActions(token);

  const [loading, setLoading] = useState(false);
  const [referenceCheck, setReferenceCheck] = useState(null);

  const formRef = useRef(null);

  // Data
  const [referees, setReferees] = useState([]);
  const [currentReferee, setCurrentReferee] = useState(0);
  const [currentForm, setCurrentForm] = useState(null);

  // Set check
  useEffect(() => {
    if (!fetched) return;

    if (replacement) {
      setLoading(true);
      dispatch(getFormByRefereeId({ token, refereeId: replacement })).then(({ payload }) => {
        const referenceCheck = payload.reference_check;
        let form_detail = referenceCheck.details.form_details[payload.index];
        setReferenceCheck(referenceCheck);
        setReferees([
          { candidate_form_id: form_detail.candidate, referee_form_id: form_detail.referee },
        ]);
        setLoading(false);
      });
    } else {
      const referenceCheck = candidate_checks.find(check => check.type === 'custom_reference');
      setReferenceCheck(referenceCheck);

      setReferees(
        referenceCheck.details.form_details.map(form_detail => ({
          candidate_form_id: form_detail.candidate,
          referee_form_id: form_detail.referee,
        })),
      );
    }
  }, [fetched]);

  // Set current form
  useEffect(() => {
    if (referees.length < 1) return;

    setCurrentForm(forms.find(form => form.id === referees[currentReferee].candidate_form_id));
  }, [currentReferee, forms, referees.length]);

  // Reset form
  useEffect(() => {
    if (!currentForm) return;

    formRef.current?.reset();
  }, [currentForm]);

  const onSubmit = async answers => {
    let _referees = [...referees];
    _referees[currentReferee].answers = answers;

    if (replacement) {
      _referees[currentReferee].replacement = replacement;
    }

    if (currentReferee < _referees.length - 1) {
      formRef.current.reset();
      setReferees(_referees);
      setCurrentReferee(index => index + 1);
    } else {
      setLoading(true);
      await dispatch(
        saveCustomReference({ token, id: referenceCheck.id, referees: _referees, user_agent }),
      );
      if (shouldSubmitCandidate(referenceCheck, candidate_checks, check_types)) {
        await dispatch(submitCandidateApplication({ token, user_agent }));
        setLoading(false);
        navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
      } else {
        setLoading(false);
        navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
      }
    }
  };

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        {referenceCheck && (
          <CandidatePage.Card className={styles.root}>
            <img src="assets/images/icons/id_icon.svg" className={styles.icon} />
            <h1 className={cn(styles.headertitle, 'title-4 u-padding-top u-padding-bottom')}>
              Your Referees
            </h1>
            <p className={cn(styles.title)}>
              {replacement
                ? 'Nominate your backup referee'
                : `Nominate your ${numberToWords.toWordsOrdinal(currentReferee + 1)} ${currentForm?.name}`}
            </p>
            {currentForm?.description && (
              <p className={cn(styles.subtitle)}>{currentForm.description}</p>
            )}
            <div className={cn(styles.stepsWrapper, 'u-margin-bottom')}>
              {Array.from(Array(referees.length).keys()).map(index => (
                <span
                  key={index}
                  style={
                    index <= currentReferee
                      ? { backgroundColor: brand.color_button, color: '#FFF', border: 'none' }
                      : {}
                  }
                  className={cn(styles.step)}>
                  {index + 1}
                </span>
              ))}
            </div>
            <Form
              candidateId={candidate.id}
              country={country}
              ref={formRef}
              textParams={{ candidate: candidate.first_name, job: candidate.job_role }}
              form={currentForm}
              brand={brand}
              buttonTitle={referees.length === currentReferee + 1 ? 'Finish' : 'Next'}
              onSubmit={onSubmit}
              getCandidateUpload={getUpload}
              deleteCandidateUpload={deleteUpload}
              signCandidateUpload={signUpload}
              createCandidateUpload={createUpload}
            />
          </CandidatePage.Card>
        )}
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateCustomReference;
