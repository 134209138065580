import { useState } from 'react';

import { Delete02Icon } from '@hugeicons/core-free-icons';
import cn from 'classnames';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { Tab, Tabs } from 'react-bootstrap';

import Alert from 'components/Alert/Alert';
import { IconButton } from 'components/IconButton/IconButton';
import LinkShare from 'components/LinkShare/LinkShare';

import { CandidateAnswers } from '../CandidateAnswers/CandidateAnswers';
import { RefereeMetadataDetailsModal } from '../RefereeMetadataDetailsModal/RefereeMetadataDetailsModal';
import { ReplaceRefereeModal } from '../ReplaceRefereeModal/ReplaceRefereeModal';
import { RefereeDetails } from './../RefereeDetails/RefereeDetails';
import { RefereeReport } from './../RefereeReport/RefereeReport';
import styles from './Referee.module.scss';

export const Referee = ({
  candidate,
  check,
  company,
  referee,
  messagePopUp = () => {},
  updateReferee = () => {},
  deleteReferee = () => {},
  cancelReferee = () => {},
  setLoading = () => {},
  getPdfReport = () => {},
  setShowRequestAlert = () => {},
  onNewRequest = () => {},
  sendReplacementRequestEmail = () => {},
  onClose = () => {},
}) => {
  const [tab, setTab] = useState();

  const [showDeletionAlert, setShowDeletionAlert] = useState(false);
  const [showMetadataDetails, setShowMetadaDetails] = useState(false);
  const [showReplacementModal, setShowReplacementModal] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const handleUpdate = async data => {
    setLoading(true);
    await updateReferee(data.id, data);
    setLoading(false);
    messagePopUp('Referee updated', MESSAGE_STATE_SUCCESS);
    if (!referee.completed_at) setShowRequestAlert(true);
  };

  const handleDownloadReport = () => {
    const filename = `${candidate.first_name}_${candidate.last_name}-${referee.first_name}_${referee.last_name}.pdf`;
    getPdfReport(referee.id, filename);
  };

  const handleDeletion = () => {
    setShowDeletionAlert(false);
    onClose();
    deleteReferee(referee.id);
  };

  const handleRefereeCancelation = () => {
    setShowReplacementModal(false);
    onClose();
    cancelReferee(referee.id);
  };

  const handleReplacementRequest = async message => {
    setLoading(true);
    setShowReplacementModal(false);
    await sendReplacementRequestEmail({ id: referee.id, message });
    messagePopUp('Referee replacement request sent', MESSAGE_STATE_SUCCESS);
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <Alert
        show={showDeletionAlert}
        title={`Are you sure you want to delete ${referee?.first_name}?`}
        message="Referee deletion cannot be undone. Once deleted, we will adjust the number of referees for this candidate"
        ok="Delete"
        type="error"
        onOk={handleDeletion}
        icon={Delete02Icon}
        showConfirmationInput
        cancel="Cancel"
        onCancel={() => setShowDeletionAlert(false)}
      />
      <RefereeMetadataDetailsModal
        referee={referee}
        referenceCheck={check}
        visible={showMetadataDetails}
        onClose={() => setShowMetadaDetails(false)}
      />
      <ReplaceRefereeModal
        referee={referee}
        visible={showReplacementModal}
        onClose={() => setShowReplacementModal(false)}
        onCancel={handleRefereeCancelation}
        onReplacementRequest={handleReplacementRequest}
      />
      <div className={styles.header}>
        <div className={cn(styles.details, 'u-margin-right')}>
          <div className={styles.title}>REFEREE</div>
          <div className={styles.name}>{referee.name}</div>
        </div>
        <div className={styles.actions}>
          <LinkShare
            open={showLink}
            link={`${process.env.REACT_APP_WEB_URL}/company/report/reference/${referee.token}`}
            title="Share this report"
            onClose={() => setShowLink(false)}
          />
          <IconButton
            icon="share-2"
            tip="Link"
            onClick={() => setShowLink(true)}
            className="u-margin-right--small"
            disabled={!referee.completed}
          />
          <IconButton
            icon="users"
            tip="Ask candidate to replace referee"
            className="u-margin-right--small"
            onClick={() => setShowReplacementModal(true)}
          />
          <IconButton
            icon="send"
            tip="Send New Request to Referee"
            className="u-margin-right--small"
            onClick={onNewRequest}
          />
          <IconButton
            icon="monitor"
            tip="Metadata details"
            className="u-margin-right--small"
            onClick={() => setShowMetadaDetails(true)}
          />
          <IconButton
            icon="trash-2"
            tip="Delete"
            className="u-margin-right--small"
            onClick={() => setShowDeletionAlert(true)}
          />
          <IconButton
            prefix="fa"
            icon="download"
            tip="Download Form"
            onClick={handleDownloadReport}
            disabled={!referee.completed}
          />
        </div>
      </div>
      <Tabs
        activeKey={tab}
        defaultActiveKey={1}
        onSelect={setTab}
        className="tabs padding-small no-padding"
        mountOnEnter={true}>
        <Tab eventKey={1} title="Details">
          <RefereeDetails
            referee={referee}
            company={company}
            onSubmit={handleUpdate}
            setShowRequestAlert={setShowRequestAlert}
          />
        </Tab>
        <Tab eventKey={2} title="Candidate" disabled={!candidate.submitted_at}>
          <CandidateAnswers candidate={candidate} referee={referee} setLoading={setLoading} />
        </Tab>
        <Tab eventKey={3} title="Report" disabled={!referee.completed}>
          <RefereeReport candidate={candidate} referee={referee} setLoading={setLoading} />
        </Tab>
      </Tabs>
    </div>
  );
};
