import { useEffect, useRef } from 'react';

import cn from 'classnames';
import { useApiUploadActions } from 'hooks/upload-actions';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import { Button, EditableTitle } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';

export const BundleEdit = ({
  bundle,
  company,
  forms,
  brands,
  companyChecks,
  onUpdate = () => {},
  onDelete = () => {},
  onClose = () => {},
}) => {
  const selectorRef = useRef(null);

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const { register, control, handleSubmit, reset } = useForm();

  const nameValue = useWatch({ control, name: 'name' });

  useEffect(() => {
    if (!bundle) return;

    reset({ name: bundle.name });
    selectorRef.current.reset({
      checks: bundle.checks_data,
      candidate: { brand_id: bundle.brand_id },
    });
  }, [bundle]);

  const onSubmit = data => {
    const { checks, candidate } = data.checks_data;
    onUpdate({ ...data, checks_data: checks, brand_id: candidate.brand_id });
  };

  return (
    <form
      className={cn('card', 'card-with-border', 'card--small')}
      onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div
          className={cn(
            'u-margin--large',
            'u-flex-box',
            'u-flex-justify-between',
            'u-flex-align-center',
          )}>
          <div>
            <h3 className={cn('title-6', 'u-padding-bottom--small')}>({bundle.id})</h3>
            <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
              <EditableTitle register={register} name="name" value={nameValue} />
            </div>
            <p className="t-small">Configure and manage your bundle below</p>
          </div>
          <IconButton icon="trash-2" onClick={onDelete} tip="Delete bundle" />
        </div>
        <div className={cn('background-secondary', 'u-padding--large', 'card_border--top')}>
          <Controller
            control={control}
            name="checks_data"
            render={({ field: { onChange } }) => (
              <ChecksSelector
                ref={selectorRef}
                company={company}
                brands={brands}
                companyChecks={companyChecks}
                forms={forms}
                getUpload={getUpload}
                signUpload={signUpload}
                createUpload={createUpload}
                deleteUpload={deleteUpload}
                onChange={onChange}
                allowUploads
              />
            )}
          />
        </div>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type="secondary">
          Cancel
        </Button>
        <Button submit>Save</Button>
      </div>
    </form>
  );
};
