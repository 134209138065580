import React, { useEffect, useState } from 'react';

import { UserGroupIcon } from '@hugeicons/core-free-icons';
import { UserIcon } from '@hugeicons/core-free-icons';
import cn from 'classnames';
import numberToWords from 'number-to-words';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { InputGroup, Select } from 'components/FormComponents';
import { Button, FilePicker, ToggleSwitch } from 'components/FormComponents';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { IconComponent } from 'components/Icon/IconComponent';

import styles from './CustomReferenceCheckDetails.module.scss';

export const CustomReferenceCheckDetails = ({
  index,
  append,
  remove,
  register,
  control,
  watch,
  bulk,
  documents,
  credits = 0,
  forms = [],
  allowUploads = false,
  setValue = () => {},
  signUpload = () => {},
  createUpload = () => {},
  handleDeleteUpload = () => {},
}) => {
  const { t } = useTranslation();
  const fieldName = `checks.${index}.details`;

  const {
    fields: referees,
    append: appendReferee,
    remove: removeReferee,
  } = useFieldArray({ control, name: `checks.${index}.details.form_details` });

  const refereesValue = watch(`checks.${index}.details.referees`);
  const documentId = watch(`checks.${index}.details.referee_document_id`);

  // Data
  const [enabled, setEnabled] = useState(false);

  const [upload, setUpload] = useState();
  const [showNewUploadModal, setShowNewUploadModal] = useState(false);

  const useCustomForms = watch(`${fieldName}.use_custom_forms`) || false;
  const commonCandidateForm = watch(`${fieldName}.form_details.0.candidate`) || '';
  const commonRefereeForm = watch(`${fieldName}.form_details.0.referee`) || '';

  const updateCommonForms = (candidateForm, refereeForm) => {
    (referees || []).forEach((_, i) => {
      if (candidateForm) setValue(`${fieldName}.form_details.${i}.candidate`, candidateForm);
      if (refereeForm) setValue(`${fieldName}.form_details.${i}.referee`, refereeForm);
    });
  };

  const handleCommonCandidateChange = value => {
    updateCommonForms(value, null);
  };

  const handleCommonRefereeChange = value => {
    updateCommonForms(null, value);
  };

  useEffect(() => {
    if (enabled && isNaN(index)) {
      let initialReferees;
      try {
        initialReferees = JSON.parse(localStorage.getItem('customReference:referees') || '2');
      } catch (e) {
        initialReferees = 2;
      }

      let backupReferee = false;
      try {
        backupReferee = JSON.parse(
          localStorage.getItem('customReference:backupReferee') || 'false',
        );
      } catch (e) {
        backupReferee = false;
      }

      append({
        type: 'custom_reference',
        details: {
          referees: initialReferees,
          backup_referee: backupReferee,
          bypassed: false,
          form_details: [],
        },
      });
      Array.from({ length: initialReferees }).forEach(() => {
        appendReferee({
          candidate: commonCandidateForm || '',
          referee: commonRefereeForm || '',
        });
      });
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled]);

  useEffect(() => {
    if (index >= 0 && !enabled) setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index]);

  useEffect(() => {
    if (refereesValue === (referees || []).length) {
      return;
    } else if (refereesValue > (referees || []).length) {
      const refereesToAdd = refereesValue - (referees || []).length;
      Array.from({ length: refereesToAdd }).forEach(() => {
        appendReferee({
          candidate: commonCandidateForm || '',
          referee: commonRefereeForm || '',
        });
      });
    } else {
      for (let i = 0; i < referees?.length; i++) {
        if (i >= refereesValue) removeReferee(i);
      }
    }
  }, [refereesValue, referees?.length]);

  const setUploadedDocument = () => {
    if (upload?.id) {
      setValue(`checks.${index}.details.referee_document_id`, upload.id);
      setShowNewUploadModal(false);
    }
  };

  return (
    <ToggleSwitchInput
      text={t('check.referenceCheckPlus.title')}
      subtext={(credits < 0 ? 'Unlimited' : credits) + ' remaining'}
      logo="https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/checkmate_check_logo.svg"
      onChange={e => setEnabled(e.target.checked)}
      value={enabled}>
      {enabled && index >= 0 && (
        <>
          <AnimatedModal
            className="u-width-small"
            visible={showNewUploadModal}
            title={t('common.uploadFile')}
            onClose={() => setShowNewUploadModal(false)}>
            <FilePicker
              value={upload}
              onChange={setUpload}
              accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
              useModel
              className="u-margin-y"
              signUpload={signUpload}
              createUpload={createUpload}
              deleteUpload={handleDeleteUpload}
            />
            <Button className="u-width-100" onClick={setUploadedDocument}>
              Done
            </Button>
          </AnimatedModal>

          <InputGroup title={t('check.referenceCheckPlus.totalReferees')}>
            <Select
              name={`checks.${index}.details.referees`}
              placeholder={t('common.selectPlaceholder')}
              register={register}
              value={refereesValue}
              useDefault>
              <Select.Item value={1}>1 Referee</Select.Item>
              <Select.Item value={2}>2 Referees</Select.Item>
              <Select.Item value={3}>3 Referees</Select.Item>
              <Select.Item value={4}>4 Referees</Select.Item>
              <Select.Item value={5}>5 Referees</Select.Item>
            </Select>
          </InputGroup>

          <div
            className={cn({
              [styles.disableFormSelector]: useCustomForms,
            })}>
            <FormSelector
              title={t('common.candidateForm')}
              forms={forms.candidates}
              value={commonCandidateForm}
              onChange={!useCustomForms ? handleCommonCandidateChange : () => {}}
            />
            <FormSelector
              title={t('common.refereeForm')}
              forms={forms.referees}
              value={commonRefereeForm}
              onChange={!useCustomForms ? handleCommonRefereeChange : () => {}}
            />
          </div>

          <ToggleSwitchInput
            value={useCustomForms}
            onChange={e => {
              setValue(`${fieldName}.use_custom_forms`, e.target.checked);
              if (!e.target.checked) {
                updateCommonForms(commonCandidateForm, commonRefereeForm);
              }
            }}
            register={register(`${fieldName}.use_custom_forms`)}
            name={`${fieldName}.use_custom_forms`}
            icon={UserGroupIcon}
            text={t('check.referenceCheckPlus.selectDifferentFormsForEachReferee')}
            cardClassName={cn('surface-secondary', useCustomForms && 'u-padding-bottom--0')}>
            {useCustomForms && (
              <>
                {referees.map((_, index) => (
                  <div
                    key={index}
                    className={cn(
                      'u-border-radius',
                      'background-secondary',
                      'u-border',
                      'u-padding--small',
                      'u-padding-top',
                      'u-margin-bottom',
                    )}>
                    <RefereeDetails
                      register={register}
                      watch={watch}
                      fieldName={fieldName}
                      index={index}
                      forms={forms}
                    />
                  </div>
                ))}
              </>
            )}
          </ToggleSwitchInput>

          {(allowUploads || documents?.length > 0) && (
            <InputGroup title="Documents">
              <Select
                name={`checks.${index}.details.referee_document_id`}
                placeholder={t('common.document')}
                register={register}
                value={documentId}>
                {upload && <Select.Item value={upload.id}>{upload.file_name}</Select.Item>}
                {documents?.map(document => (
                  <Select.Item key={document.id} value={document.id}>
                    {document.file_name}
                  </Select.Item>
                ))}
              </Select>
              {allowUploads && (
                <a className="u-link dark t-small" onClick={() => setShowNewUploadModal(true)}>
                  {t('common.uploadNewDocument')}
                </a>
              )}
            </InputGroup>
          )}
          <ToggleSwitch
            register={register}
            name={`checks.${index}.details.backup_referee`}
            tooltip={t('check.referenceCheckPlus.backupRefereeTooltip')}
            label={t('check.referenceCheckPlus.backupReferee')}
          />
          <ToggleSwitch
            register={register}
            name={`checks.${index}.details.referee_confirmation`}
            tooltip={t('check.referenceCheckPlus.refereeConfirmationTooltip')}
            label={t('check.referenceCheckPlus.refereeConfirmation')}
          />
          {!bulk && (
            <ToggleSwitch
              register={register}
              name={`checks.${index}.details.bypassed`}
              tooltip={t('check.referenceCheckPlus.bypassCandidateTooltip')}
              label={t('check.referenceCheckPlus.bypassCandidate')}
            />
          )}
        </>
      )}
    </ToggleSwitchInput>
  );
};

const RefereeDetails = ({ register, watch, fieldName, index, forms }) => {
  const { t } = useTranslation();
  const candidateValue = watch(`${fieldName}.form_details.${index}.candidate`);
  const refereeValue = watch(`${fieldName}.form_details.${index}.referee`);

  return (
    <div className={styles.refereeDetails}>
      <div className={cn('d-flex', 'align-items-center', 'u-margin-bottom')}>
        <IconComponent icon={UserIcon} noBackground border={false} type="neutral-light" small />
        <div className={styles.refereeCardinal}>
          {numberToWords.toWordsOrdinal(index + 1)} Referee
        </div>
      </div>
      <div className={styles.details}>
        <FormSelector
          title={t('common.candidateForm')}
          forms={forms.candidates}
          value={candidateValue}
          register={register}
          name={`${fieldName}.form_details.${index}.candidate`}
        />
        <FormSelector
          title={t('common.refereeForm')}
          forms={forms.referees}
          value={refereeValue}
          register={register}
          name={`${fieldName}.form_details.${index}.referee`}
        />
      </div>
    </div>
  );
};

const FormSelector = ({ title, forms, value, onChange, register, name }) => {
  const { t } = useTranslation();
  return (
    <InputGroup title={title}>
      <Select
        name={name}
        placeholder={t('common.selectPlaceholder')}
        register={register}
        value={value}
        inputProps={onChange && { onChange: e => onChange(e.target.value) }}
        useDefault>
        {(forms || [])
          .filter(form => !form.archived)
          .map(form => (
            <Select.Item key={form.id} value={form.id}>
              {form.name}
            </Select.Item>
          ))}
      </Select>
    </InputGroup>
  );
};
