import { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HugeiconsIcon } from '@hugeicons/react';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './IconComponent.module.scss';

export const IconComponent = ({
  icon,
  className,
  tip,
  placement = 'top',
  prefix = 'fi',
  size = 'original',
  // Available types:
  // - 'default': Standard checkmate Gradient style
  // - 'neutral-light': Light grey color
  // - 'neutral-dark': Dark grey color
  // - 'success': Green success indicator
  // - 'error': Red error/danger indicator
  // - 'warning': Yellow warning indicator
  // - 'info': Blue information indicator
  type = 'default',
  rounded = true,
  border = true,
  noBackground = false,
  oldIconLibrary = false,
  strokeWidth = 2,
  onClick = () => {},
}) => {
  const getSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 16;
      case 'large':
        return 28;
      case 'xlarge':
        return 32;
      default:
        return 20;
    }
  }, [size]);

  return (
    <OverlayTrigger
      trigger={tip === undefined ? 'none' : ['hover', 'focus']}
      placement={placement}
      overlay={<Tooltip>{tip}</Tooltip>}>
      <div
        onClick={onClick}
        className={cn(styles.root, styles[type], {
          [className]: className,
          [styles[size]]: size,
          [styles['no-rounded']]: !rounded,
          [styles['with-border']]: border,
          [styles['no-background']]: noBackground,
        })}>
        {!oldIconLibrary ? (
          <HugeiconsIcon
            size={getSize}
            icon={icon}
            className={styles.icon}
            strokeWidth={strokeWidth}
          />
        ) : prefix === 'fa' ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          <FeatherIcon size={getSize} icon={icon} className={styles.icon} />
        )}
      </div>
    </OverlayTrigger>
  );
};

IconComponent.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  tip: PropTypes.string,
  placement: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  rounded: PropTypes.bool,
};
