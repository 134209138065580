import { useEffect, useState } from 'react';

import {
  Archive03Icon,
  Delete02Icon,
  Download02Icon,
  Notification02Icon,
  Tag01Icon,
  UserAdd01Icon,
} from '@hugeicons/core-free-icons';
import cn from 'classnames';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CandidateAssignModal } from 'views/AdminApp/AdminCandidate/components/CandidateAssignModal/CandidateAssignModal';

import Alert from 'components/Alert/Alert';
import { IconComponent } from 'components/Icon/IconComponent';
import { IconButton } from 'components/IconButton/IconButton';

import { TagsMenu } from '../../../../components/TagsMenu/TagsMenu';
import styles from './BulkActionsBar.module.scss';

export const BulkActionsBar = ({
  active,
  selectedIds,
  tags,
  users,
  onUseAllChange = () => {},
  onClose: handleClose = () => {},
  reminderAction = () => {}, // Action: Send bulk reminders
  downloadCSV = () => {}, // Action: Download candidates report
  bulkTag = () => {}, // Action: Assign tag
  bulkTagDeletion = () => {}, // Action: remove all tags
  bulkDeletion = () => {}, // Action: remove
  bulkArchive = () => {}, // Action: archive
  bulkAssignCandidate = () => {},
}) => {
  const [useAll, setUseAll] = useState(false);

  useEffect(() => setUseAll(false), [selectedIds]);

  useEffect(() => onUseAllChange(useAll), [useAll]);

  // Tags
  const [tagMenuVisible, setTagMenuVisible] = useState(false);

  // Deletion
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  const onTagSelected = tagId => {
    setTagMenuVisible(false);
    bulkTag(tagId);
  };

  const onTagsDeletion = () => {
    setTagMenuVisible(false);
    bulkTagDeletion();
  };

  const [candidataAssignVisible, setCandidataAssignVisible] = useState(false);

  const onSelectCandidateAssign = userId => {
    setCandidataAssignVisible(false);
    bulkAssignCandidate(userId);
  };

  const onClose = () => {
    setUseAll(false);
    handleClose();
  };

  return (
    <div className={cn(styles.root, 'u-drop-shadow--large', { [styles.hidden]: !active })}>
      <Alert
        show={showDeletionAlert}
        title={`Are you sure you want to delete all the ${selectedIds.length} selected candidates?`}
        message="Deletion cannot be undone. Are you sure you want to continue?"
        ok="Delete"
        type="error"
        onOk={bulkDeletion}
        icon={Delete02Icon}
        showConfirmationInput
        cancel="Cancel"
        onCancel={() => setShowDeletionAlert(false)}
      />
      <Badge className={styles.badge} onClick={() => setUseAll(!useAll)}>
        {useAll ? 'use all' : `${selectedIds.length} selected`}
      </Badge>
      <div className={styles.divider} />
      <div className={styles.actionsWrapper}>
        <OverlayTrigger placement="top" overlay={<Tooltip>Send reminder emails & SMS</Tooltip>}>
          <div>
            <IconComponent
              icon={Notification02Icon}
              type="neutral"
              noBackground
              onClick={reminderAction}
            />
          </div>
        </OverlayTrigger>

        <div style={{ position: 'relative' }}>
          <OverlayTrigger placement="top" overlay={<Tooltip>Tag</Tooltip>}>
            <div>
              <IconComponent
                icon={Tag01Icon}
                type="neutral"
                noBackground
                onClick={() => setTagMenuVisible(!tagMenuVisible)}
              />
            </div>
          </OverlayTrigger>
          <TagsMenu
            visible={tagMenuVisible}
            tags={tags}
            onClose={() => setTagMenuVisible(false)}
            onSelect={onTagSelected}
            bulkTagDeletion={onTagsDeletion}
          />
        </div>

        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
          <div>
            <IconComponent
              icon={Delete02Icon}
              type="neutral"
              noBackground
              onClick={() => setShowDeletionAlert(true)}
            />
          </div>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={<Tooltip>Archive</Tooltip>}>
          <div>
            <IconComponent icon={Archive03Icon} type="neutral" noBackground onClick={bulkArchive} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={<Tooltip>Export CSV</Tooltip>}>
          <div>
            <IconComponent
              icon={Download02Icon}
              type="neutral"
              noBackground
              onClick={downloadCSV}
            />
          </div>
        </OverlayTrigger>

        <div style={{ position: 'relative' }}>
          <OverlayTrigger placement="top" overlay={<Tooltip>Assign Candidate</Tooltip>}>
            <div>
              <IconComponent
                icon={UserAdd01Icon}
                type="neutral"
                noBackground
                onClick={() => setCandidataAssignVisible(!candidataAssignVisible)}
              />
            </div>
          </OverlayTrigger>
          <CandidateAssignModal
            users={users}
            visible={candidataAssignVisible}
            onSubmit={onSelectCandidateAssign}
            onClose={() => setCandidataAssignVisible(false)}
          />
        </div>
        <IconButton icon="x" onClick={onClose} />
      </div>
    </div>
  );
};
