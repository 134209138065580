import { useMemo, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'components/FormComponents';
import { Input } from 'components/FormComponents';
import { IconComponent } from 'components/Icon/IconComponent';

import styles from './Alert.module.scss';

const Alert = ({
  show = false,
  title,
  message,
  ok,
  cancel,
  type = 'neutral',
  params = {},
  icon,
  showConfirmationInput = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [confirmationText, setConfirmationText] = useState('');

  const getButtonType = useMemo(() => {
    switch (type) {
      case 'error':
        return 'delete';
      default:
        return type;
    }
  }, [type]);

  const isConfirmationValid =
    !showConfirmationInput || confirmationText.toLowerCase() === ok.toLowerCase();

  return (
    <div className={cn(styles.root, { [styles.rootHidden]: !show }, styles[type])}>
      <div className={styles.content}>
        <div
          className={cn(
            styles.header,
            'd-flex',
            'justify-content-start',
            'align-items-start',
            'flex-column',
          )}>
          {icon && (
            <div className="u-margin-right">
              <IconComponent icon={icon} type={type} border={false} />
            </div>
          )}
          <div className={styles.contentTitle}>{title}</div>
        </div>
        <div className={cn(styles.contentMessage)}>{message}</div>
        {showConfirmationInput && (
          <div className={cn(styles.contentConfirmationInput, 'u-margin-y')}>
            <Input
              name="confirmation"
              placeholder={`Type '${ok}' to confirm`}
              inputProps={{
                value: confirmationText,
                onChange: e => setConfirmationText(e.target.value),
              }}
            />
          </div>
        )}
        <div className={styles.contentButtons}>
          {cancel && (
            <Button type="secondary" onClick={onCancel} className="cancel-btn button">
              {cancel}
            </Button>
          )}
          {ok && (
            <Button
              type={getButtonType}
              onClick={() => onOk(params)}
              className="button"
              disabled={!isConfirmationValid}>
              {ok}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;

Alert.propTypes = {
  type: PropTypes.oneOf(['neutral', 'success', 'error', 'warning', 'info']),
  title: PropTypes.string,
  message: PropTypes.string,
  ok: PropTypes.string,
  cancel: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};
